var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "div-form-main",
    },
    [
      _c(
        "div",
        { staticClass: "div-form-main-btn" },
        [
          _vm.view === _vm.TERM_VIEW.CREATE_TERM
            ? _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: { round: "", type: "primary", size: "small" },
                  on: { click: _vm.onCreateOrUpdate },
                },
                [_vm._v("Submit")]
              )
            : _vm._e(),
          _vm.view === _vm.TERM_VIEW.EDIT_TERM && !_vm.isAllFieldsDisabled
            ? _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: { round: "", type: "primary", size: "small" },
                  on: { click: _vm.onCreateOrUpdate },
                },
                [_vm._v("Save")]
              )
            : _vm._e(),
          _vm.view === _vm.TERM_VIEW.EDIT_TERM &&
          _vm.isAllFieldsDisabled &&
          _vm.hasPermission(_vm.$permissionFunctionCode.EDIT)
            ? _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: {
                    round: "",
                    type: "primary",
                    size: "small",
                    disabled:
                      !_vm.hasEditPermission &&
                      _vm.termContractType === _vm.TERM_CONTRACT_TYPE.TC,
                  },
                  on: {
                    click: function ($event) {
                      _vm.isAllFieldsDisabled = false
                    },
                  },
                },
                [_vm._v(" Edit ")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticStyle: { "min-width": "100px" },
              attrs: { round: "", type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.handleCloseForm("CLOSE")
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
      _vm.view === _vm.TERM_VIEW.CREATE_TERM ||
      (_vm.view === _vm.TERM_VIEW.EDIT_TERM && _vm.termContractFormData)
        ? _c(
            "div",
            { staticClass: "div-form-main-container-second term-contract" },
            [
              _c("contract-infomation", {
                ref: "contractInfoForm",
                staticClass: "basic-card-block",
                attrs: {
                  title: "Contract Infomation",
                  formData: _vm.termContractFormData,
                  rules: _vm.rules,
                  isEdit: _vm.view === _vm.TERM_VIEW.EDIT_TERM,
                  hasEditPermission: _vm.hasEditPermission,
                  termContractType: _vm.termContractType,
                  currentCompany: _vm.currentCompany,
                  isAllFieldsDisabled: _vm.isAllFieldsDisabled,
                  companyOptionList: _vm.creditAssociationsCompanyOptionList,
                },
                on: {
                  "update:formData": function ($event) {
                    _vm.termContractFormData = $event
                  },
                  "update:form-data": function ($event) {
                    _vm.termContractFormData = $event
                  },
                },
              }),
              _vm.creditAssociationsCompanyOptionList.length > 0
                ? _c("company-infomation", {
                    ref: "sellerInfoForm",
                    staticClass: "basic-card-block",
                    attrs: {
                      isSellerForm: "",
                      rules: _vm.rules,
                      title: [
                        _vm.TERM_CONTRACT_TYPE.EX_WHARF,
                        _vm.TERM_CONTRACT_TYPE.DELIVERED,
                      ].includes(_vm.termContractType)
                        ? "Seller Infomation"
                        : "Owner Infomation",
                      formData: _vm.termContractFormData,
                      isEdit: _vm.view === _vm.TERM_VIEW.EDIT_TERM,
                      hasEditPermission: _vm.hasEditPermission,
                      termContractType: _vm.termContractType,
                      isAllFieldsDisabled: _vm.isAllFieldsDisabled,
                      currentCompany: _vm.currentCompany,
                      companyOptionList:
                        _vm.creditAssociationsCompanyOptionList,
                    },
                    on: {
                      "update:formData": function ($event) {
                        _vm.termContractFormData = $event
                      },
                      "update:form-data": function ($event) {
                        _vm.termContractFormData = $event
                      },
                      getBargesListBySeller: _vm.getBargesListBySeller,
                    },
                  })
                : _vm._e(),
              _vm.creditAssociationsCompanyOptionList.length > 0
                ? _c("company-infomation", {
                    ref: "buyerInfoForm",
                    staticClass: "basic-card-block",
                    attrs: {
                      rules: _vm.rules,
                      title: [
                        _vm.TERM_CONTRACT_TYPE.EX_WHARF,
                        _vm.TERM_CONTRACT_TYPE.DELIVERED,
                      ].includes(_vm.termContractType)
                        ? "Buyer Infomation"
                        : " Charterer Infomation",
                      formData: _vm.termContractFormData,
                      isEdit: _vm.view === _vm.TERM_VIEW.EDIT_TERM,
                      hasEditPermission: _vm.hasEditPermission,
                      termContractType: _vm.termContractType,
                      isAllFieldsDisabled: _vm.isAllFieldsDisabled,
                      currentCompany: _vm.currentCompany,
                      companyOptionList:
                        _vm.creditAssociationsCompanyOptionList,
                    },
                    on: {
                      "update:formData": function ($event) {
                        _vm.termContractFormData = $event
                      },
                      "update:form-data": function ($event) {
                        _vm.termContractFormData = $event
                      },
                      getBargesListBySeller: _vm.getBargesListBySeller,
                    },
                  })
                : _vm._e(),
              [_vm.TERM_CONTRACT_TYPE.TC, _vm.TERM_CONTRACT_TYPE.COA].includes(
                _vm.termContractType
              )
                ? _c("barge-information", {
                    ref: "bargeInfoForm",
                    staticClass: "basic-card-block",
                    attrs: {
                      rules: _vm.rules,
                      title: "Barge Information",
                      formData: _vm.bargeFormData,
                      isEdit: _vm.view === _vm.TERM_VIEW.EDIT_TERM,
                      termContractType: _vm.termContractType,
                      isAllFieldsDisabled: _vm.isAllFieldsDisabled,
                      bargeOptionList: _vm.bargeOptionList,
                      entityData: _vm.termContractFormData,
                    },
                  })
                : _vm._e(),
              _c("product-information", {
                ref: "productInfoForm",
                staticClass: "basic-card-block",
                attrs: {
                  rules: _vm.rules,
                  view: _vm.view,
                  title: "Product Information",
                  formData: _vm.productFormData,
                  isEdit: _vm.view === _vm.TERM_VIEW.EDIT_TERM,
                  hasEditPermission: _vm.hasEditPermission,
                  termContractType: _vm.termContractType,
                  isAllFieldsDisabled: _vm.isAllFieldsDisabled,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }